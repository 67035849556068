<template>
  <module-container
    :title="$t('audits')"
    hide-new-button
    route-name="audits"
    show-toolbar
  >
    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class AuditsIndex extends Mixins(PaginateProviderMixin) {}
</script>
